// const DOMPurify = require("dompurify")

export const onServiceWorkerUpdateReady = () => window.location.reload(true)

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV === "production") {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    setTimeout(() => {
      if (typeof window.gtag === "function") {
        window.gtag("event", "page_view", { page_path: pagePath })
      }
    }, 100)
  }
}

// Added because blog is now nested under company route
export const onClientEntry = () => {
  const pagePath = window.location.href

  const requiresBlogRedirect =
    pagePath.includes("/blog") && !pagePath.includes("company")

  if (requiresBlogRedirect) {
    window.location.replace(pagePath.replace("/blog", "/company/blog"))
  }
}

export const shouldUpdateScroll = (prevRouterProps, { location }) => {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName("body")[0]
  body.scrollTop = 0
  return false
}

// if (window.trustedTypes && window.trustedTypes.createPolicy) {
//   window.trustedTypes.createPolicy("default", {
//     createHTML: string =>
//       DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true }),
//     createScriptURL: string => string, // warning: this is unsafe! - we need to implement a handler
//     createScript: string => string, // warning: this is unsafe! - we need to implement a handler
//   })
// }
